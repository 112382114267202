import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import useWindowSize from "../../utils/useWindowSize";
import PageView from "../pageView/PageView";
import logo from "../../assets/image/logo.png";
import headerData from "./headerData";
import NavDrawer from "../navDrawer/NavDrawer";

const Header = () => {
  const { pathname } = useLocation();
  const { width } = useWindowSize();

  return (
    <div className="py-[32px]">
      <PageView>
        <div className="flex items-center justify-center">
          <Link to="/">
            <img src={logo} alt="logo" className="h-[48px] aspect-auto" />
          </Link>
        </div>
      </PageView>
    </div>
  );
};

export default Header;
