import HomePage from "../pages/homePage/HomePage";
import ProductPage from "../pages/productPage/ProductPage";

const appRoutes = [
  {
    id: "home",
    path: "/",
    exact: true,
    component: HomePage,
  },
  {
    id: "product",
    path: "/products",
    exact: true,
    component: ProductPage,
  },

 
];

export default appRoutes;
