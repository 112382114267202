import cn from "classnames";

const PageView = ({ className = "", children }) => {
  return (
    <div className={cn("max-w-[1040px] px-3 mx-auto", className)}>
      {children}
    </div>
  );
};

export default PageView;
