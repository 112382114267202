import PageView from "../../components/pageView/PageView";

const ProductPage = () => {
  return (
    <div className="my-[160px]">
      <PageView>
        {/* Hero Section */}
        <section className=" max-w-[560px]   ">
          <span className="display-2 text-neutral-800">
            <span> Hi! I am </span>
          </span>
        </section>
      </PageView>
    </div>
  );
};

export default ProductPage;
