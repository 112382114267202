import PageView from "../../components/pageView/PageView";

import ProductCard from "../../components/ui/ProdutCard";

const HomePage = () => (
  <div className="display-2 w-full flex text-center justify-center text-neutral-800">
    Coming Soon...
  </div>
);

export default HomePage;
