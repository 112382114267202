import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "../components/header/Header";
// import Footer from "../components/footer/Footer";
import appRoutes from "./appRoutes";
import { useEffect, useState } from "react";
import ScrollToTop from "./scroll-to-top";
// import { IconChevronUp } from "@tabler/icons";

const scrolltoTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

const Router = () => {
  const [displayScrollButton, setDisplayScrollButton] = useState(false);

  useEffect(() => {
    const scrollEvent = document.addEventListener("scroll", () => {
      console.log(document.documentElement.scrollTop);
      if (document.documentElement.scrollTop >= 800) {
        setDisplayScrollButton(true);
      } else setDisplayScrollButton(false);
    });

    return () => window.removeEventListener("scroll", scrollEvent);
  }, []);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {appRoutes.map((route) => {
          return (
            <Route
              key={route.id}
              exact={route.exact}
              path={route.path}
              element={
                <div>
                  <Header />
                  <route.component />
                  {/* <Footer /> */}
                  {displayScrollButton && (
                    <button
                      className="fixed bottom-[50px] right-[50px]"
                      onClick={scrolltoTop}
                    >
                      <div
                        className="p-[8px] bg-white items-center justify-center rounded-full 
                        shadow-[-4px_-2px_80px_8px_rgba(235,33,81,0.40)]
                        transition ease-in-out delay-150 duration-300 hover:scale-110  "
                      >
                        {/* <IconChevronUp
                          size={16}
                          color="#EB2151"
                          stroke={1.5}
                          className="items-center justify-center w-full h-full "
                        /> */}
                      </div>
                    </button>
                  )}
                </div>
              }
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
